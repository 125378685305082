var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"border-a border-0 custome-header-bg",attrs:{"disable-sort":true,"headers":_vm.headers,"items":_vm.installment_list,"items-per-page":_vm.$keys.PAGE_LENGTH,"loading":_vm.table_loading,"hide-default-footer":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-size":"larger","font-weight":"bold"}},[_vm._v(_vm._s(item.number_of_emi))]),_c('p',[_vm._v(_vm._s(item.due_emi))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"pt-2"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"pt-1"},[_vm._v(_vm._s(item.mobile))]),_c('p',{staticClass:"pb-2"},[_vm._v(_vm._s(item.email))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",staticStyle:{"height":"auto !important"},attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"blue-grey lighten-5 inner-table px-10",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tr',{staticClass:"pa-2 border-b"},[_c('th',{staticClass:"text-left pa-1 pt-2 text-right",staticStyle:{"width":"55%"}},[_vm._v(" EMI# ")]),_c('th',{staticClass:"text-left pa-1 pt-2 text-center",staticStyle:{"width":"20%"}},[_vm._v(" EMI Date ")]),_c('th',{staticClass:"text-left pa-1 pt-2 pr-5  text-right",staticStyle:{"width":"10%"}},[_vm._v(" EMI Amt ")])]),_vm._l((item.installment_list),function(installment,i){return _c('tr',{key:i,staticClass:"border-b"},[_c('td',{staticClass:"text-left pa-1 text-right"},[_vm._v(" #"+_vm._s(installment.installment_number)+" ")]),_c('td',{staticClass:"text-left pa-1 text-center"},[_vm._v(" "+_vm._s(installment.payment_due_date)+" ")]),_c('td',{staticClass:"text-left pa-1 pr-5 text-right"},[_vm._v(" "+_vm._s(installment.installment_amount)+" ")])])})]},proxy:true}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }